import './App.css';
import Navbar from './Pages/Navigation/Navbar'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
//Pages
import Home from './Pages/Home';
import ServiceInfo from './Pages/ServiceInfo';
// import AdminLogin from './Pages/AdminLogin';
// import AdminDashboard from './Pages/AdminDashboard';

function App() {
  return (
    <Router>
      <>
        <Navbar />
          <Route render={({ location }) => (
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={300}
                classNames="fade"
              >
                <Switch location={location} >
                  <Route path='/' exact component={Home} />
                  <Route path='/goals-services' exact component={ServiceInfo} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>

          )} />

      </>
    </Router>
  );
}

export default App;
