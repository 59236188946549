import React from 'react'
import '../Styles/ClientPreview.css'

function ClientPreview(props) {
    return (
        <div className='client-container'>
            <div style={props.desc ? { gridTemplateColumns: '40% 60%' } : { gridTemplateColumns: '100%' }} key={props.key} className='client-preview'>
                <div className='client-preview-pic'>
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderBottom: ' 0.5px solid var(--boldBlue)' }}>
                        {/* <img src={props.src} alt={props.header} /> */}
                        <h1>{props.clientName}</h1>
                    </div>
                    <p>{props.header}</p>
                </div>
                {/* <div className='client-preview-desc'>
                    <p>{props.desc}</p>
                </div> */}
            </div>
        </div>
    )
}

export default ClientPreview;