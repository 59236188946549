export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    // {
    //     title: 'Experience',
    //     url: '#',
    //     cName: 'nav-links'
    // },
    {
        title: 'Goals and Services',
        url: '/goals-services',
        cName: 'nav-links'
    }
]