/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { MenuItems } from "./Items"
// import { TipsDropdown, DataDropdown } from './Dropdown';
import { Link } from 'react-router-dom';
import './Navbar.css'
import { Button } from '../Components/Button';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
        this.state = {
            clicked: false,
            dropdown1: false,
            dropdown2: false,
            dropdown3: false,
            dropdown4: false
        }
    }

    handleLogout() {
        this.props.logout()
        this.props.history.push('/logging-out')
    }


    handleClick(hasDropdown, dropdownIndex) {
        if (hasDropdown) {
            this.setDropdown([dropdownIndex], !this.state[dropdownIndex]);
        } else {
            this.setState({ clicked: !this.state.clicked })
        }
    }

    handleClose() {
        this.setState({ clicked: false })
    }
    setDropdown(dropdownIndex, condition) {
        this.setState({ [dropdownIndex]: condition })
    }

    onMouseEnter(dropdownIndex) {
        if (window.innerWidth < 960) {
            this.setDropdown(dropdownIndex, false);
        } else {
            this.setDropdown(dropdownIndex, true);
        }
    };

    onMouseLeave(dropdownIndex) {
        if (window.innerWidth < 960) {
            this.setDropdown(dropdownIndex, false);
        } else {
            this.setDropdown(dropdownIndex, false);
        }
    };

    render() {
        const { clicked } = this.state
        return (
            <nav style={{}} className="NavbarItems">
                <div className="navbar-logo">
                    <img alt='MINERBA' src='/images/logo.png' />
                </div>
                <div className="menu-icon" onClick={() => this.handleClick()}>
                    <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                    <Fragment>
                        {
                            MenuItems.map((item, index) => {
                                return (
                                    <li className='nav-item' key={index}>
                                        <Link onClick={() => this.handleClick()} className={item.cName} to={item.url}>
                                            {item.title}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </Fragment>
                </ul>
                {/* <a style={{ color: 'inherit', textDecoration: 'none' }} href='mailto:example@gmail.com'>
                    <Button >
                        Contact Us
                    </Button>
                </a> */}
            </nav>
        )
    }
}



export default (Navbar);