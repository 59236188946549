import React from 'react'

function ImagePreview(props) {
    return (
        <div key={props.key} className='preview-image'>
            {/* <h1>{props.header}</h1> */}
            <div className='preview-image-pic'>
                <img src={props.src} alt={props.alt} />
            </div>
        </div>
    )
}

export default ImagePreview;